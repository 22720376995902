import {CertificationsEndDate} from './CertificationsEndDate'
import {AppDispatch} from 'Store'
import {
  addCertification,
  editCertification,
  getTrainingTitles,
  getEducationInstitutions,
  deleteCertification,
} from 'Store/Actions'
import {connect} from 'react-redux'
import {RootState} from 'Store/Reducers'
import {CertificationFormikValue} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  profile: state.seeker,
  certifications: state.seeker.data?.full?.trainings,
  mdCertificates: state.seeker.data?.full?.seekerJbCertificates,
  taxonomies: state.taxonomies,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addCertification: (value: CertificationFormikValue) =>
    dispatch(addCertification(value)),
  editCertification: (value: CertificationFormikValue) =>
    dispatch(editCertification(value)),
  deleteCertification: (id: string) => dispatch(deleteCertification(id)),
  getTaxonomies: () => {
    dispatch(getTrainingTitles())
    dispatch(getEducationInstitutions())
  },
})
export const CertificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationsEndDate)
