export const profile = {
  'profile.languagesTitle': 'JEZIKI',
  'profile.drivingLicenceTitle': 'Vozniško dovoljenje',
  'profile.editDrivingLicence': 'Urejanje vozniškega dovoljenja',
  'profile.skillsTitle': 'ZNANJA IN SPRETNOSTI',
  'profile.certificationsTitle': 'Dodatna izobraževanja in Certifikati',
  'profile.trainingTitlesTitle': 'MojeDelo.com Certifikati',
  'profile.projectsTitle': 'PROJEKTI',
  'profile.awardsTitle': 'NAGRADE',
  'profile.attachmentsTitle': 'PRIPONKE',
  'profile.desiredOccupationsPlaceholder': 'Izberite do 3 želena delovna mesta',
  'profile.confirmButton': 'Potrdite vaš profil',
  'profile.editAboutMe': 'Urejanje O meni',
  'profile.jobSearchStatus': 'Status iskanja zaposlitve',
  'profile.shortDescription': 'Kratek opis',
  'profile.placeholderAboutMe': `Primer: Inženir elektrotehnike, specializiran za razvoj in implementacijo novih telekomunikacijskih rešitev. Izkušnje s projektnim vodenjem ter vodenjem raziskav in razvoja.`,
  'profile.managedDataText':
    'S svojimi osebnimi in kontaktnimi podatki lahko upravljate v nastavitvah uporabniškega računa.',
  'profile.careerLevel': 'Karierni nivo',
  'profile.jobCategories': 'Področja dela',
  'profile.desiredEmploymentType': 'Želena vrsta zaposlitve',
  'profile.editJobPreferences': 'Urejanje delovnih preferenc',
  'profile.placeholderTown': 'Vnesite mesto ...',
  'profile.jobTitle': 'Delovno mesto',
  'profile.jobClassification': 'Klasifikacija poklica',
  'profile.placeholderJobTitle': 'Vnesite naziv svojega delovnega mesta ...',
  'profile.employmentType': 'Vrsta zaposlitve',
  'profile.employmentDate': 'Datum zaposlitve',
  'profile.myCurrentEmployer': 'To je moj trenutni delodajalec',
  'profile.jobDescription': 'Opis dela in dosežki',
  'profile.placeholderJobDescription':
    'Primer: Vodil sem projekte na področju UI/UX, s katerimi smo za 25% izboljšali uporabniško izkušnjo svojih strank in prispevali k boljši oceni in posledično prodaji naše storitve.',
  'profile.educationTitle': 'Naziv izobrazbe',
  'profile.placeholderEducationTitle': 'Vnesite naziv izobrazbe ...',
  'profile.educationLevel': 'Stopnja izobrazbe',
  'profile.educationalInstitution': 'Izobraževalna ustanova',
  'profile.placeholderEducationalInstitution':
    'Vnesite izobraževalno ustanovo ...',
  'profile.educationDate': 'Datum izobrazbe',
  'profile.yearOfCompletion': 'Leto zaključka',
  'profile.educationInProgress': 'Izobrazba še v teku',
  'profile.educationDescription': 'Opis izobrazbe (Opcijsko)',
  'profile.placeholderEducationDescription':
    'Primer: Inženir elektrotehnike, specializiran za razvoj in implementacijo novih telekomunikacijskih rešitev. Izkušnje s projektnim vodenjem ter vodenjem raziskav in razvoja.',
  'profile.languageSkills': 'Znanja tujih jezikov',
  'profile.editLanguage': 'Urejanje jezikov',
  'profile.addLanguage': 'Dodajte jezik',
  'profile.language': 'Jezik',
  'profile.languages': 'Jeziki',
  'profile.languageLevel': 'raven jezika',
  'profile.yourCategories': 'Vaše kategorije',
  'profile.categories': 'Kategorije',
  'profile.ownMeansOfTransport': 'Lastno prevozno sredstvo:',
  'profile.placeholderCompanyName': 'Vnesite naziv podjetja ...',
  'profile.companyName': 'Naziv podjetja',
  'profile.skill': 'Znanje',
  'profile.skillAdd': 'Dodajte znanje',
  'profile.skillEdit': 'Urejanje Znanj in spretnosti',
  'profile.selectSkillLevel': 'Izberite vaš nivo znanja',
  'profile.great': 'Odlično',
  'profile.veryGood': 'Zelo dobro',
  'profile.good': 'Dobro',
  'profile.basic': 'Osnovno',
  'profile.beginner': 'Začetno',
  'profile.addAdditionalTraining':
    'Dodajte dodatno izobraževanje/certifikacijo',
  'profile.trainingTitle': 'NAZIV IZOBRAŽEVANJA/CERTIFIKACIJE',
  'profile.placeholderTrainingTitle': 'Vpišite naziv izobraževanja',
  'profile.trainingDate': 'LETO IZOBRAŽEVANJA/CERTIFIKACIJE',
  'profile.institutionName': 'Naziv ustanove',
  'profile.trainingDescription': 'Opis izobraževanja',
  'profile.placeholderTrainingDescription':
    'Primer: V času trimesečnega tečaja sem usvojil znanja s področja konstruiranja strojev in naprav, na nadaljevalnem tečaju pa še na področju industrijskih sistemov.',
  'profile.addProjects': 'Dodajte projekte ...',
  'profile.editAdditionalTraining':
    'Urejanje Dodatnih izobraževanj in Certifikacij',
  'profile.projectTitle': 'Naziv projekta',
  'profile.placeholderProjectTitle': 'Vnesite naziv projekta ...',
  'profile.projectDate': 'Leto projekta',
  'profile.projectDescription': 'Opis projekta',
  'profile.placeholderProjectDescription':
    'Namen tega projekta je bilo preučiti različne sinergije med znanstvenimi študijami ter predstaviti izsledke, kako je mogoče upravljati vprašanja trajnostnega podnebja z orodji Big Data.',
  'profile.editProject': 'Urejanje Projektov',
  'profile.addAward': 'Dodajte nagrado',
  'profile.awardTitle': 'Naziv nagrade',
  'profile.placeholderAwardTitle': 'Vnesite naziv nagrade...',
  'profile.awardDate': 'Leto nagrade',
  'profile.awardDateMonth': 'Datum nagrade (mesec)',
  'profile.awardDateYear': 'Leto nagrade',
  'profile.awardDescription': 'Opis nagrade',
  'profile.placeholderAwardDescription':
    'Primer: To nagrado sem dobil/-a za svoj odlični nastop ...',
  'profile.editAward': 'Urejanje nagrad',
  'profile.addAttachment': 'Dodajte priponko',
  'profile.attachmentType': 'Vrsta priponke',
  'profile.uploadAttachment': 'Naložite priponko',
  'profile.attachmentName': 'Naziv priponke',
  'profile.editAttachments': 'Urejanje priponk',
  'profile.profileConfirmationTextPart1':
    'Prosimo preglejte svoj življenjepis. V potrditev in pregled ga lahko pošljete, ko dosežete ',
  'profile.profileConfirmationBoldText': ' 85% izpolnitev profila, ',
  'profile.profileConfirmationTextPart2': 'ki jo lahko preverite na vašem ',
  'profile.profileConfirmationLink': ' namizju. ',
  'profile.profileConfirmationTextPart3':
    'Naši HR specialisti bodo poslani profil pregledali. Dokler vaš profil ni odobren, ga ne boste mogli urejati, lahko pa nadaljujete z reševanjem psihometričnega in kognitivnega testa. ',
  'profile.deletePartOne': 'Ste prepričani, da želite izbrisati zapis ',
  'profile.deletePartTwo': ' ?',
  'publicprofile.occupationalField': 'Področje dela',
  'publicprofile.available': 'Na voljo',
  'publicprofile.desiredWorkEnv': 'Želeno delovno okolje',
  'publicprofile.placeholderDesiredWorkEnv':
    'Primer: Rad/a bi sodeloval/a z nadarjeno ekipo oblikovalcev, ki sodeluje s strankami pri oblikovanju ambicioznih produktov z uporabo Ruby in Rails...',
  'publicprofile.totalYearsOfExp': 'Skupno število let delovnih izkušenj',
  'publicprofile.workExpPerPos': 'Delovne izkušnje glede na delovno mesto',
  'publicprofile.workExpPerPosInYears':
    'Delovne izkušnje glede na delovno mesto (v letih)',
  'publicprofile.workFromHome': 'Delo od doma',
  'publicprofile.age': 'Starost',
  'profile.present': 'V teku',
  'profile.cv': 'CV',
  'profile.certificate': 'Certifikat',
  'profile.references': 'Priporočilo',
  'profile.diploma': 'Diploma',
  'profile.award': 'Nagrada',
  'profile.other': 'Ostalo',
  'profile.endDateInProgress': 'V teku (predvidoma ',
  'publicprofile.preferredRegions': 'Želene regije',
  'profile.profileConfirmedTitle': 'Profil poslan v pregled',
  'profile.profileConfirmedText':
    'Vaš CV profil je bil poslan v pregled našim HR specialistom',
  'profile.anonymousCandidate': 'Anonimni kandidat',
  'profile.yearsOld': ' let',
  'profile.areYouSure': 'Ste prepričani?',
  'profile.areYouSureYouWant1':
    'Ste prepričani, da želite svoj profil poslati v pregled? Ko profil pošljete v pregled, ga ne morete urejati, dokler ga ne pregledajo naši HR specialisti. ',
  'profile.confirmProfile': 'Potrdite profil',
  'profile.contactCandidate': 'Prošnja za stik',
  'profile.contactSentCandidate': 'Zahtevek za kontakt je v teku',
  'profile.myCv': 'Moj CV',
  'profile.parseCv': 'Uvozite življenjepis',
  'profile.reparseCv': 'Ponovno uvozite življenjepis',
  'profile.parseYourCv': 'Uvozite svoj življenjepis',
  'profile.reparseYourCv': 'Ponovno uvozite svoj življenjepis',
  'profile.parsingNotificationAboutReadingData':
    'Sistem bo prebral vaš CV in vnesel relevantne podatke s področja delovnih izkušenj, izobrazbe, znanja tujih jezikov in veščin v vaš profil. ',
  'profile.dataWillBeOverwrittenNotification':
    'Če ste že vnesli podatke ali uvozili svoj življenjepis, je možno, da se bodo podatki prepisali. Po uvozu preverite vse podatke in se prepričajte, da so vnešeni pravilno.',
  'profile.cvNotification':
    'Vaš življenjepis je viden samo tistim delodajalcem, katerih prošnje za stik ste sprejeli. Vsi drugi vidijo vaš anonimiziran karierni profil. Preverite ga lahko v',
  'profile.publicProfileLink': 'Moj profil/Karierni profil.',
  'profile.employer': 'Delodajalec',
  'profile.location': 'Lokacija',
  'profile.trainingYear': 'Leto izobraževanja/certifikacije',
  'profile.projectYear': 'Leto projekta',
  'profile.enterShortDescription': 'Vnesite kratek opis',
  'profile.rateYourSkill': 'Ocenite stopnjo znanja',
  'profile.sendContactRequest': 'Pošlji prošnjo za stik',
  'profile.mdCertificates': 'MojeDelo.com Certifikati',
}
