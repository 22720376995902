export const profile = {
  'profile.languagesTitle': 'Languages',
  'profile.drivingLicenceTitle': 'Driving Licence',
  'profile.editDrivingLicence': 'Edit Driving licence',
  'profile.skillsTitle': 'Skills',
  'profile.certificationsTitle': 'Additional trainings & Certifications',
  'profile.trainingTitlesTitle': 'MojeDelo.com Certificates',
  'profile.projectsTitle': 'Projects',
  'profile.awardsTitle': 'Awards',
  'profile.attachmentsTitle': 'Attachments',
  'profile.desiredOccupationsPlaceholder':
    'Enter up to 3 desired job positions',
  'profile.confirmButton': 'Confirm your Profile',
  'profile.editAboutMe': 'Edit about me',
  'profile.jobSearchStatus': 'Job search status',
  'profile.shortDescription': 'Short description',
  'profile.placeholderAboutMe': `Example: »Electrical engineer specialized for development and implementation of new telecommunications solutions. 
Experienced in project management, researching and development.«`,
  'profile.managedDataText':
    'Your personal and contact data are managed in user account settings',
  'profile.careerLevel': 'Career level',
  'profile.jobCategories': 'Job categories',
  'profile.desiredEmploymentType': 'Desired employment type',
  'profile.editJobPreferences': 'Edit Job preferences',
  'profile.placeholderTown': 'Enter city ...',
  'profile.jobTitle': 'Job title',
  'profile.jobClassification': 'Job Classification',
  'profile.placeholderJobTitle': 'Enter your job title ...',
  'profile.employmentType': 'Employment type',
  'profile.employmentDate': 'Employment date',
  'profile.myCurrentEmployer': 'This is my current employer',
  'profile.jobDescription': 'Work description and achievements',
  'profile.placeholderJobDescription':
    'Example: I worked in a relaxed atmosphere, where there was a lot of teamwork ...',
  'profile.educationTitle': 'Education title',
  'profile.placeholderEducationTitle': 'Enter education title ...',
  'profile.educationLevel': 'Education level',
  'profile.educationalInstitution': 'Educational institution',
  'profile.placeholderEducationalInstitution':
    'Enter educational institution ...',
  'profile.educationDate': 'Education date',
  'profile.yearOfCompletion': 'Year of completion',
  'profile.educationInProgress': 'Education still in progress',
  'profile.educationDescription': 'Education description (Optional)',
  'profile.placeholderEducationDescription':
    'Example: During my studies I gained a lot of knowledge ...',
  'profile.languageSkills': 'Language skills',
  'profile.editLanguage': 'Edit language',
  'profile.addLanguage': 'Add language',
  'profile.language': 'Language',
  'profile.languages': 'Languages',
  'profile.languageLevel': 'Language level',
  'profile.yourCategories': 'Your Categories',
  'profile.categories': 'Categories',
  'profile.ownMeansOfTransport': 'Own means of transport:',
  'profile.placeholderCompanyName': 'Enter company name ...',
  'profile.companyName': 'Company name',
  'profile.skill': 'Skill',
  'profile.skillAdd': 'Add Skill',
  'profile.skillEdit': 'Edit Skills',
  'profile.selectSkillLevel': 'Select your skill level',
  'profile.great': 'Great',
  'profile.veryGood': 'Very Good',
  'profile.good': 'Good',
  'profile.basic': 'Basic',
  'profile.beginner': 'Beginner',
  'profile.addAdditionalTraining': 'Add Additional trainings & Certifications',
  'profile.trainingTitle': 'Training title',
  'profile.placeholderTrainingTitle': 'Type training title',
  'profile.trainingDate': 'Training/Certification year',
  'profile.institutionName': 'Institution name',
  'profile.trainingDescription': 'Training description',
  'profile.placeholderTrainingDescription':
    'Example: I got this award for my outstanding performance...',
  'profile.editAdditionalTraining':
    'Edit Additional trainings & Certifications',
  'profile.addProjects': 'Add projects',
  'profile.projectTitle': 'Project title',
  'profile.placeholderProjectTitle': 'Enter Project title...',
  'profile.projectDate': 'Project year',
  'profile.projectDescription': 'Project description',
  'profile.placeholderProjectDescription':
    'The aim of this paper is to provide an overview of the interrelationship between data science and climate studies, as well as describes how sustainability climate issues can be managed using the Big Data tools.',
  'profile.editProject': 'Edit project',
  'profile.addAward': 'Add Awards',
  'profile.awardTitle': 'Award title',
  'profile.placeholderAwardTitle': 'Enter award title ...',
  'profile.awardDate': 'Award year',
  'profile.awardDateMonth': 'Award date (month)',
  'profile.awardDateYear': 'Award year',
  'profile.awardDescription': 'Award description',
  'profile.placeholderAwardDescription':
    'Example: I got this award for my outstanding performance...',
  'profile.editAward': 'Edit award',
  'profile.addAttachment': 'Add attachment',
  'profile.attachmentType': 'Attachment type',
  'profile.uploadAttachment': 'Upload attachment',
  'profile.attachmentName': 'Attachment name',
  'profile.editAttachments': 'Edit attachments',
  'profile.profileConfirmationTextPart1':
    'Please review your profile. You can send it to review when you reach ',
  'profile.profileConfirmationBoldText': '85% profile completness, ',
  'profile.profileConfirmationTextPart2': 'which you can check on your ',
  'profile.profileConfirmationLink': 'Dashboard. ',
  'profile.profileConfirmationTextPart3': `Once you confirm your profile, our HR specialists will review it. You will not be able to edit your profile untill it's confirmed. In the meantime, you can proceed with psychometric and cognitive testing. `,
  'profile.deletePartOne': 'Are you sure you want to delete the ',
  'profile.deletePartTwo': ' record?',
  'profile.warning': 'Opozorilo',
  'publicprofile.occupationalField': 'Occupational field',
  'publicprofile.available': 'Available',
  'publicprofile.desiredWorkEnv': 'Desired work environment',
  'publicprofile.placeholderDesiredWorkEnv':
    'Example: I‘d love to collaborate with a talented customer-facing design team to build ambitious products using Ruby and Rails... ...',
  'publicprofile.totalYearsOfExp': 'Total years of work experiences',
  'publicprofile.workExpPerPos': 'Work experience per position',
  'publicprofile.workExpPerPosInYears':
    'Work experience per position (in years)',
  'publicprofile.workFromHome': 'Work from home',
  'publicprofile.age': 'Age',
  'profile.present': 'Present',
  'profile.cv': 'CV',
  'profile.certificate': 'Certificate',
  'profile.references': 'References',
  'profile.diploma': 'Diploma',
  'profile.award': 'Award',
  'profile.other': 'Other',
  'profile.endDateInProgress': 'In progress (predicted ',
  'publicprofile.preferredRegions': 'Preferred regions',
  'profile.profileConfirmedTitle': 'Profile confirmed',
  'profile.profileConfirmedText':
    'Your profile is now sent to HR expert for review',
  'profile.anonymousCandidate': 'Anonymous candidate',
  'profile.yearsOld': ' years old',
  'profile.areYouSure': 'Are you sure?',
  'profile.areYouSureYouWant1':
    'Are you sure you want to send your profile to review? Once you confirm it, you will not be able to edit it until it’s confirmed. ',
  'profile.confirmProfile': 'Confirm profile',
  'profile.contactCandidate': 'Request contact',
  'profile.contactSentCandidate': 'Contact Request Sent',
  'profile.myCv': 'My CV',
  'profile.parseCv': 'Parse CV',
  'profile.reparseCv': 'Reparse CV',
  'profile.parseYourCv': 'Parse your CV',
  'profile.reparseYourCv': 'Reparse your CV',
  'profile.parsingNotificationAboutReadingData':
    'CV parser will read your CV and take relevant data and add work experience, education, language and skills to your profile.',
  'profile.dataWillBeOverwrittenNotification':
    'If you already entered data or parsed your CV, some data may be overwritten. Please check all data after CV parsing to see if it parsed correctly.',
  'profile.cvNotification':
    'Your CV is only visible to those employers whose contact requests you’ve accepted.  Everyone else sees your anonymised career profile. You can check it out in',
  'profile.publicProfileLink': 'My profile/Career profile.',
  'profile.employer': 'Employer',
  'profile.location': 'Location',
  'profile.trainingYear': 'Training Year',
  'profile.projectYear': 'Project Year',
  'profile.enterShortDescription': 'Enter short description',
  'profile.rateYourSkill': 'Rate your skill level',
  'profile.sendContactRequest': 'Send contact request',
  'profile.mdCertificates': 'MojeDelo.com Certificates',
}
