export enum ProfileTranslations {
  desiredOccupationsPlaceholder = 'profile.desiredOccupationsPlaceholder',
  languagesTitle = 'profile.languagesTitle',
  skillsTitle = 'profile.skillsTitle',
  certificationsTitle = 'profile.certificationsTitle',
  trainingTitlesTitle = 'profile.trainingTitlesTitle',
  drivingLicenceTitle = 'profile.drivingLicenceTitle',
  editDrivingLicence = 'profile.editDrivingLicence',
  projectsTitle = 'profile.projectsTitle',
  awardsTitle = 'profile.awardsTitle',
  attachmentsTitle = 'profile.attachmentsTitle',
  confirmButton = 'profile.confirmButton',
  editAboutMe = 'profile.editAboutMe',
  jobSearchStatus = 'profile.jobSearchStatus',
  shortDescription = 'profile.shortDescription',
  placeholderAboutMe = 'profile.placeholderAboutMe',
  managedDataText = 'profile.managedDataText',
  careerLevel = 'profile.careerLevel',
  jobCategories = 'profile.jobCategories',
  desiredEmploymentType = 'profile.desiredEmploymentType',
  editJobPreferences = 'profile.editJobPreferences',
  placeholderTown = 'profile.placeholderTown',
  jobTitle = 'profile.jobTitle',
  jobClassification = 'profile.jobClassification',
  placeholderJobTitle = 'profile.placeholderJobTitle',
  employmentType = 'profile.employmentType',
  placeholderCompanyName = 'profile.placeholderCompanyName',
  companyName = 'profile.companyName',
  employmentDate = 'profile.employmentDate',
  jobDescription = 'profile.jobDescription',
  placeholderJobDescription = 'profile.placeholderJobDescription',
  educationTitle = 'profile.educationTitle',
  placeholderEducationTitle = 'profile.placeholderEducationTitle',
  educationLevel = 'profile.educationLevel',
  educationalInstitution = 'profile.educationalInstitution',
  placeholderEducationalInstitution = 'profile.placeholderEducationalInstitution',
  educationDate = 'profile.educationDate',
  yearOfCompletion = 'profile.yearOfCompletion',
  educationInProgress = 'profile.educationInProgress',
  educationDescription = 'profile.educationDescription',
  placeholderEducationDescription = 'profile.placeholderEducationDescription',
  languageSkills = 'profile.languageSkills',
  editLanguage = 'profile.editLanguage',
  addLanguage = 'profile.addLanguage',
  language = 'profile.language',
  languages = 'profile.languages',
  languageLevel = 'profile.languageLevel',
  drivingLicence = 'profile.drivingLicence',
  yourCategories = 'profile.yourCategories',
  categories = 'profile.categories',
  ownMeansOfTransport = 'profile.ownMeansOfTransport',
  myCurrentEmployer = 'profile.myCurrentEmployer',
  skill = 'profile.skill',
  skillAdd = 'profile.skillAdd',
  skillEdit = 'profile.skillEdit',
  selectSkillLevel = 'profile.selectSkillLevel',
  great = 'profile.great',
  veryGood = 'profile.veryGood',
  good = 'profile.good',
  basic = 'profile.basic',
  addAdditionalTraining = 'profile.addAdditionalTraining',
  trainingTitle = 'profile.trainingTitle',
  placeholderTrainingTitle = 'profile.placeholderTrainingTitle',
  trainingDate = 'profile.trainingDate',
  institutionName = 'profile.institutionName',
  trainingDescription = 'profile.trainingDescription',
  placeholderTrainingDescription = 'profile.placeholderTrainingDescription',
  editAdditionalTraining = 'profile.editAdditionalTraining',
  addProjects = 'profile.addProjects',
  projectTitle = 'profile.projectTitle',
  placeholderProjectTitle = 'profile.placeholderProjectTitle',
  projectDate = 'profile.projectDate',
  projectDescription = 'profile.projectDescription',
  placeholderProjectDescription = 'profile.placeholderProjectDescription',
  editProject = 'profile.editProject',
  addAward = 'profile.addAward',
  awardTitle = 'profile.awardTitle',
  placeholderAwardTitle = 'profile.placeholderAwardTitle',
  awardDate = 'profile.awardDate',
  awardDateMonth = 'profile.awardDateMonth',
  awardDateYear = 'profile.awardDateYear',
  awardDescription = 'profile.awardDescription',
  placeholderAwardDescription = 'profile.placeholderAwardDescription',
  editAward = 'profile.editAward',
  addAttachment = 'profile.addAttachment',
  attachmentType = 'profile.attachmentType',
  uploadAttachment = 'profile.uploadAttachment',
  attachmentName = 'profile.attachmentName',
  editAttachments = 'profile.editAttachments',
  profileConfirmationTextPart1 = 'profile.profileConfirmationTextPart1',
  profileConfirmationBoldText = 'profile.profileConfirmationBoldText',
  profileConfirmationTextPart2 = 'profile.profileConfirmationTextPart2',
  profileConfirmationLink = 'profile.profileConfirmationLink',
  profileConfirmationTextPart3 = 'profile.profileConfirmationTextPart3',
  deletePartOne = 'profile.deletePartOne',
  deletePartTwo = 'profile.deletePartTwo',
  occupationalField = 'publicprofile.occupationalField',
  available = 'publicprofile.available',
  desiredWorkEnv = 'publicprofile.desiredWorkEnv',
  placeholderDesiredWorkEnv = 'publicprofile.placeholderDesiredWorkEnv',
  totalYearsOfExp = 'publicprofile.totalYearsOfExp',
  workExpPerPos = 'publicprofile.workExpPerPos',
  workExpPerPosInYears = 'publicprofile.workExpPerPosInYears',
  workFromHome = 'publicprofile.workFromHome',
  age = 'publicprofile.age',
  present = 'profile.present',
  cv = 'profile.cv',
  certificate = 'profile.certificate',
  references = 'profile.references',
  diploma = 'profile.diploma',
  award = 'profile.award',
  other = 'profile.other',
  endDateInProgress = 'profile.endDateInProgress',
  preferredRegions = 'publicprofile.preferredRegions',
  profileConfirmedTitle = 'profile.profileConfirmedTitle',
  profileConfirmedText = 'profile.profileConfirmedText',
  anonymousCandidate = 'profile.anonymousCandidate',
  yearsOld = 'profile.yearsOld',
  areYouSure = 'profile.areYouSure',
  areYouSureYouWant1 = 'profile.areYouSureYouWant1',
  confirmProfile = 'profile.confirmProfile',
  contactCandidate = 'profile.contactCandidate',
  contactSentCandidate = 'profile.contactSentCandidate',
  myCv = 'profile.myCv',
  parseCv = 'profile.parseCv',
  reparseCv = 'profile.reparseCv',
  parseYourCv = 'profile.parseYourCv',
  reparseYourCv = 'profile.reparseYourCv',
  parsingNotificationAboutReadingData = 'profile.parsingNotificationAboutReadingData',
  dataWillBeOverwrittenNotification = 'profile.dataWillBeOverwrittenNotification',
  cvNotification = 'profile.cvNotification',
  publicProfileLink = 'profile.publicProfileLink',
  employer = 'profile.employer',
  location = 'profile.location',
  trainingYear = 'profile.trainingYear',
  projectYear = 'profile.projectYear',
  enterShortDescription = 'profile.enterShortDescription',
  rateYourSkill = 'profile.rateYourSkill',
  sendContactRequest = 'profile.sendContactRequest',
  mdCertificates = 'profile.mdCertificates',
}
